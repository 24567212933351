import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Airplane from "mdi-material-ui/Airplane";
import "./FlightCardMini.css";
import {formatTimeString, formatToMonthDate, ConvertTravelTime} from '../../common/Utils';
const IMAGE_PATH = process.env.REACT_APP_FLIGHT_CARRIER_IMG;

class FlightCardMini extends Component {
  render() {
    var flight = this.props.flight;
    return (
      <div className="flight-card-mini">
        <Grid container className="flight-info text-center">
          <Grid item lg={12} md={12} sm={12} xs={12} className="flt-img">
            <Typography variant="body2">
              <img className="vertical-middle" alt="Flight" src={IMAGE_PATH + flight.carrier + ".jpg"} />
              &nbsp;
              <span>
                {flight.carriername} - ({flight.carrier}&nbsp;{flight.flightnumber}) - <span className="capitalize">{flight.cabinclass}</span>
              </span>
            </Typography>
            <br />
          </Grid>
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <Typography variant="subtitle1" className="bold">
              {flight.origin_name} ({flight.origin})
            </Typography>
            <small> on {formatToMonthDate(flight.depdate)} , </small>
            <Typography variant="subtitle1" className="bold">
              {formatTimeString(flight.deptime)}
            </Typography>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Airplane className="rotate-90" />
            <Typography variant="subtitle1" className="bold">
              {ConvertTravelTime(flight.journeyduration)}
            </Typography>
          </Grid>
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <Typography variant="subtitle1" className="bold">
              {flight.destination_name} ({flight.destination})
            </Typography>
            <small> on {formatToMonthDate(flight.arrdate)} , </small>
            <Typography variant="subtitle1" className="bold">
              {formatTimeString(flight.arrtime)}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default FlightCardMini;
