import React from 'react';
import ReviewFlight from '../../components/flights/booking/ReviewFlight'

const FlightBookingPreviewPage = () => {
    return (
        <div className="flight-booking-preview-page">
            <div>
                <ReviewFlight
                journeyType={this.journeyType}
                domint={this.domint}
                selectedFlights={this.allSelectedFlights}
              />
            </div>
        </div>
    )
}

export default FlightBookingPreviewPage;
