import {
  TG_LS_PROFILE,
  TG_LS_USER_AUTH,
  TG_LS_SERVICE_CONFIG,
  B2C_EMAIL,
} from "./constants";
import qs from "query-string";
import axios from "axios";
import moment from "moment";
const API_PATH = process.env.REACT_APP_MAIN_URL;

export const getProfile = () => {
  let obj = localStorage.getItem(TG_LS_PROFILE);
  return JSON.parse(obj);
};

export const getUserAuth = () => {
  let obj = localStorage.getItem(TG_LS_USER_AUTH);
  return JSON.parse(obj);
};

export const getServiceConfig = () => {
  const config = localStorage.getItem(TG_LS_SERVICE_CONFIG);
  if (config !== null) {
    return JSON.parse(config);
  }
  return undefined;
};

function getSearchParams(params) {
  if (params) {
    var authToken = getUserAuth();
    if (authToken) {
      params.authtoken = authToken.authtoken;
    }
    return qs.stringify(params);
  }
}

export const loginClient = function (reqData) {
  let data = getSearchParams(reqData);
  let defaultOptions = {
    baseURL: `${API_PATH}servicedispatch.jsp?opid=init&${data}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  return axios.create(defaultOptions);
};

export const axiosClient = function (routeName, reqData) {
  let defaultOptions = "";
  let data = "";
  if (reqData) {
    data = getSearchParams(reqData);
  }
  if (routeName !== "") {
    defaultOptions = {
      baseURL: API_PATH + routeName + data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  } else {
    defaultOptions = {
      baseURL: `${API_PATH}servicedispatch.jsp?${data}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }
  return axios.create(defaultOptions);
};

export const getBaseURL = function (opId) {
  var queryParam = `opid=${opId}`;
  var authToken = getUserAuth();
  if (authToken) {
    queryParam = `${queryParam}&authtoken=${authToken.authtoken}`;
  }
  return `${API_PATH}servicedispatch.jsp?${queryParam}`;
};

export const debounce = (func, wait) => {
  var timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export const getCurrencySymbol = () => {
  switch (getProfile().base_currency) {
    case "INR":
      return "₹";
    case "PHP":
      return "₱";
    case "USD":
      return "USD";
    default:
      return getProfile().base_currency;
  }
};

export const formatCurrency = (val) => {
  let fc = Math.round(val).toString().split("");
  let sl = fc.length;
  while ((sl -= 3) > 0) {
    fc.splice(sl, 0, ",");
    sl = sl - 3;
  }
  return `${getCurrencySymbol()} ${fc.join("")}`;
};

export const formatTimeString = function (time) {
  time = `${time}`;
  var hr = time.substring(0, 2);
  var min = time.substring(2);
  return `${hr}:${min}`;
};

export const ConvertTravelTime = (minutes) => {
  minutes = Math.abs(minutes);
  return `${Math.floor(minutes / 60)}h ${Math.floor(minutes % 60)}m`;
};

export const formatToMonthDate = function (date) {
  return moment(date).format("Do MMM");
};

export const getConnectingTime = function (arrtime, arrdate, deptime, depdate) {
  var tm = formatTimeString(arrtime).split(":");
  var at = moment(arrdate)
    .add(Number(tm[0]), "hours")
    .add(Number(tm[1]), "minutes");

  tm = formatTimeString(deptime).split(":");
  var dt = moment(depdate)
    .add(Number(tm[0]), "hours")
    .add(Number(tm[1]), "minutes");

  var duration = moment.duration(dt.diff(at)).asMinutes();
  return ConvertTravelTime(duration);
};

export const getType = (amType) => {
  switch (amType) {
    case "fmeal":
      return "Free Meals";
    case "fseat":
      return "Free Seat";
    case "fwifi":
      return "Free Wifi";
    case "fbaggage":
      return "Free Baggage";
    case "pmeal":
      return "Paid Meal";
    case "pseat":
      return "Paid Seat";
    case "pwifi":
      return "Paid Wifi";
    case "pbaggage":
      return "Paid Baggage";
    case "Y":
      return "Refundable";
    case "N":
      return "Non-refundable";
    case "fresc":
      return "Free Reschedule";
    case "lowcanx":
      return "Low Cancellation Fee"
    default:
      return amType;
  }
};

export const getTimeIntervals = () => {
  let timeIntervals = [];
  let quarterHours = ["00", "30"];
  for (var i = 0; i < 24; i++) {
    for (var j = 0; j < 2; j++) {
      let time = `${`0${i}`.slice(-2)}:${quarterHours[j]}`;
      timeIntervals.push({
        label: time,
        value: time,
      });
    }
  }
  return timeIntervals;
};

export const getTrainClassMappings = () => {
  return {
    "1A": "AC First Clss (1A)",
    "2A": "AC 2 Tier (2A)",
    "3A": "AC 3 Tier (3A)",
    SL: "Sleeper (SL)",
    CC: "AC Chair Car (CC)",
    "2S": "Second Sitting (2S)",
    EC: "Executive Chair (EC)",
  };
};

export const displayChat = (isChat) => {
  const chatFrame = window.document.getElementById("fc_frame");
  if (chatFrame !== null && chatFrame !== undefined) {
    if (isChat) {
      chatFrame.style.display = "block";
    } else {
      chatFrame.style.display = "none";
    }
  }
};

export const removeTripFromLS = () => {
  // localStorage.removeItem(TG_LS_TRIP_ID);
  //localStorage.removeItem(TG_LS_TRIP_NAME);
  //localStorage.removeItem(TG_LS_TRIP_ITEM_ID);
  let div = document.querySelector("#trip_product_container");
  if (div !== null) {
    div.style.display = "none";
  }
};

export const getSupportMatrix = async (corporateId) => {
  try {
    let axiosInstance = axiosClient("", {
      corporateid: corporateId,
      opid: "TG-GETSUPPORTMATRIX",
    });
    const res = await axiosInstance({ method: "post" });
    const data = res.data;
    if (data.list[0] !== "") {
      return Array.isArray(data.list[0].SupportMatrix)
        ? data.list[0].SupportMatrix
        : [data.list[0].SupportMatrix];
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const isB2C = () => {
  return getProfile().email === B2C_EMAIL;
};

export const isMobileView = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
