import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./mobile.css";
import "./print.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Main from "./components/Main";
import blue from "@material-ui/core/colors/blue";
import deepOrange from "@material-ui/core/colors/deepOrange";
import red from "@material-ui/core/colors/red";
import { LoadingProvider } from "./components/common/LoadingProvider";
import { CustomSnackBarProvider } from "./components/common/CustomSnackBarProvider";
import serviceWorker from "./serviceWorker";
const THEME = process.env.REACT_APP_THEME;

const getTheme = () => {
  let theme = {};
  if (THEME === 'true-fare') {
    theme = createMuiTheme({
      palette: {
        primary: {
          light: "#E91E63",
          main: "#D12047"
        },
        secondary: {
          light: "#148bba",
          main: "#0D5470"
        },
        error: red,
      },
      search: {
        activeBg: "#0D5470",
      },
      butbar: {
        light: "#E91E63",
        main: "#D12047"
      },
      searchFilterContainer: {
        light: "#148bba",
        main: "#0D5470"
      },
      faredetails:{
        linkcolor:"#fff",
        secondarylink:"#fff",
        bookcolor:'#D12047',
        secondary: {
          light: "#D12047",
          main: "#D12047"
        },
      },
      typography: {
        useNextVariants: true,
        fontSize: 12.5,
        fontFamily:
          "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI','Helvetica Neue',Arial,sans-serif"
      }
    });
  } else {
    theme = createMuiTheme({
      palette: {
        primary: {
          light: blue["500"],
          main: "#005e9f"
        },
        secondary: {
          light: "#f47019",
          main: deepOrange["500"]
        },
        error: red
      },
      search: {
        activeBg: "#025f9f",
      },
      butbar: {
        light: blue["500"],
        main: "#005e9f"
      },
      searchFilterContainer: {
        light: "#f47019",
        main: deepOrange["500"]
      },
      
      faredetails:{
        linkcolor:deepOrange["500"],
        secondarylink:deepOrange["500"],
        bookcolor:deepOrange["500"],
      },
      typography: {
        useNextVariants: true,
        fontSize: 12.5,
        fontFamily:
          "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI','Helvetica Neue',Arial,sans-serif"
      }
    });
  }
  return theme;
}

var Application = () => {
  return (
    <CustomSnackBarProvider>
      <LoadingProvider>
        <MuiThemeProvider theme={getTheme()}>
          <CssBaseline />
          <Main />
        </MuiThemeProvider>
      </LoadingProvider>
    </CustomSnackBarProvider>
  );
};
ReactDOM.render(<Application />, document.getElementById("root"));

serviceWorker();
