import Loadable from "react-loadable";
import {CompLoader} from './common/Loading';

const L = opts => Loadable({ loading: CompLoader, delay: 300, ...opts });

export const ValidateUserPage = L({ loader: () => import(/*webpackChunkName: "ValidateUserPage"*/ "../pages/auth/ValidateUserPage") });
export const LandingPage = L({ loader: () => import(/* webpackChunkName: "AuthPage" */ "../pages/landing-page/LandingPage") });
export const RegisterPage = L({ loader: () => import(/* webpackChunkName: "RegisterPage" */ "../pages/register-page/RegisterPage") });

export const TripGainSSOPage = L({ loader: () => import(/* webpackChunkName: "TripGainSSOPage" */ "../pages/sso/TripGainSSOPage") });
export const SSOPage = L({ loader: () => import(/* webpackChunkName: "SSOPage" */ "../pages/sso/SSOPage") });

export const RegisterSuccessPage = L({ loader: () => import(/* webpackChunkName: "RegisterSuccessPage" */ "../pages/register-page/RegisterSuccessPage") });
export const TravelPage = L({ loader: () => import(/* webpackChunkName: "TravelPage" */ "../pages/travel-page/TravelPage") });
export const TripsPage = L({ loader: () => import(/* webpackChunkName: "TripsPage" */ "../pages/trips/TripsPage") });
export const Page404 = L({ loader: () => import(/* webpackChunkName: "Page404" */ "../pages/page-not-found/Page404") });
export const FlightSearchResultsPage = L({loader: () => import(/* webpackChunkName: "FlightSearchResultsPage" */ "../pages/flights/FlightSearchResultsPage") });
export const FlightBookPage = L({ loader: () => import(/* webpackChunkName: "FlightBookPage" */ "../pages/flights/FlightBookingPage") });
export const FlightTicketPage = L({ loader: () => import(/* webpackChunkName: "FlightBookPage" */ "../pages/flights/FlightTicketPage") });

export const HotelSearchResultsPage = L({ loader: () => import(/* webpackChunkName: "HotelSearchResultsPage" */ "../pages/hotels/HotelSearchResultsPage") });
export const HotelViewPage = L({ loader: () => import(/* webpackChunkName: "HotelViewPage" */ "../pages/hotels/HotelViewPage") });
export const HotelBookingPage = L({ loader: () => import(/* webpackChunkName: "HotelBookingPage" */ "../pages/hotels/HotelBookingPage") });
export const HotelTicketPage = L({ loader: () => import(/* webpackChunkName: "HotelTicketPage" */ "../pages/hotels/HotelTicketPage") });

export const BusSearchResultsPage = L({ loader: () => import(/* webpackChunkName: "BusSearchResultsPage" */ "../pages/bus/BusSearchResultsPage") });
export const BusTicketPage = L({ loader: () => import(/* webpackChunkName: "BusTicketPage" */ "../pages/bus/BusTicketPage") });
export const BusBookingPage = L({ loader: () => import(/* webpackChunkName: "BusBookingPage" */ "../pages/bus/BusBookingPage") });

export const TrainSearchResultsPage = L({ loader: () => import(/* webpackChunkName: "TrainSearchResultsPage" */ "../pages/train/TrainSearchResultsPage") });
export const TrainTicketPage = L({ loader: () => import(/* webpackChunkName: "TrainTicketPage" */ "../pages/train/TrainTicketPage") });
export const TrainBookingPage = L({ loader: () => import(/* webpackChunkName: "TrainBookingPage" */ "../pages/train/TrainBookingPage") });

export const CabsSearchResultsPage = L({ loader: () => import(/* webpackChunkName: "CabsSearchResultsPage" */ "../pages/cabs/CabsSearchResultsPage") });
export const CabBookingPage = L({ loader: () => import(/* webpackChunkName: "CabBookingPage" */ "../pages/cabs/CabBookingPage") });
export const CabTicketPage = L({ loader: () => import(/* webpackChunkName: "CabTicketPage" */ "../pages/cabs/CabTicketPage") });

export const ProfilePage = L({ loader: () => import(/* webpackChunkName: "ProfilePage" */ "../pages/profile/ProfilePage") });
