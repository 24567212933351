export const TG_LS_PROFILE = "choozofy_b2c_profile";
export const TG_PUSH_TOKEN = "choozofy_b2c_pushToken";
export const TG_LS_USER_AUTH = "choozofy_b2c_userAuth";
export const TG_FLIGHT_SEARCH_RESULTS = "choozofy_b2c_flightSearchResults";
export const DATE_FORMAT = "Do MMM, YYYY";
export const API_REQUEST_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_WITH_TIME = "Do MMM, YYYY HH:mm";
export const TG_LS_SERVICE_CONFIG = "choozofy_b2c_serviceconfig";
export const B2C_EMAIL = "b2c@choozofy.com"
export const PARTNER_EMAIL = "s16@choozofy.com"

export const B2C_STUDENT_DETAILS = "b2c_student_details";
export const B2C_DEFENCE_DETAILS = "b2c_defence_details";
export const TG_LS_SSO_LOGIN = "ssoLogin";
export const TG_LS_COMPANY_LOGO = "companyLogo";
export const TG_LS_MASTER_LOGO = "masterLogo";
export const TG_LS_MASTER_NAME = "masterName";
export const TG_LS_MASTER_LOGO_WIDTH = "masterLogoWidth";
export const TG_LS_DEFAULT_HOME = "defaulthome";
export const TG_LS_POLICY_LINK = "POLICY_LINK";
export const TG_PARTNER_CATEGORY ="partner";
export const TG_LS_AD_LINK ="adLink";
