import { axiosClient, loginClient } from "../../components/common/Utils";
import { TG_PUSH_TOKEN } from "../../components/common/constants";

export const doRegistration = async (regObj) => {
  try {
    let axiosInstance = axiosClient("", {
      opid: "TG-ADDDIRECTUSER",
      pushtoken: null,
      jsondata: JSON.stringify({ UserProfile: regObj }),
    });
    return await axiosInstance({ method: "post" });
  } catch (error) {
    return undefined;
  }
};

export const doLogin = async (loginReq) => {
  try {
    let axiosInstance = axiosClient("", {
      opid: "TG-LOGINUSER",
      jsondata: JSON.stringify(loginReq),
      pushtoken: localStorage.getItem(TG_PUSH_TOKEN),
      devicetype: undefined,
    });
    return await axiosInstance({ method: "post" });
  } catch (error) {
    return undefined;
  }
};

export const doForgotpassword = async (email) => {
  try {
    let axiosInstance = axiosClient("", {
      opid: "TG-FORGOTPASSWORD",
      userid: email,
    });
    return await axiosInstance({ method: "post" });
  } catch (error) {
    return undefined;
  }
};

export const doInit = async (loginReq) => {
  try {
    let axiosInstance = loginClient({
      jsondata: JSON.stringify(loginReq),
      pushtoken: localStorage.getItem(TG_PUSH_TOKEN),
      devicetype: undefined,
    });
    return await axiosInstance({ method: "post" });
  } catch (error) {
    return undefined;
  }
};

export const verifyOTPNumber = async (profileId, otpValue) => {
  try {
    let axiosInstance = axiosClient("", {
      opid: "TG-ACTIVATEUSER",
      profileid: profileId,
      otp: otpValue,
      actioncode: "verifymobilelogin",
    });
    return await axiosInstance({ method: "post" });
  } catch (error) {
    return undefined;
  }
};
