import React, { Component } from "react";
import { Loading } from "./Loading";

const LoadingContext = React.createContext();
export class LoadingProvider extends Component {
  state = {
    isLoading: false,
    message: "",
    show: () => {
      this.setState({ isLoading: true });
    },
    showMessage: (message) => {
      this.setState({ isLoading: true, message });
    },
    hide: () => {
      this.setState({
        isLoading: false,
        message: "",
      });
    },
  };
  render() {
    return (
      <LoadingContext.Provider
        value={{
          ...this.state,
        }}
      >
        {this.state.isLoading && <Loading message={this.state.message} />}
        <>{this.props.children}</>
      </LoadingContext.Provider>
    );
  }
}
// create the consumer as higher order component
// eslint-disable-next-line react/display-name
export const withLoadingContext = (ChildComponent) => (props) =>
  (
    <LoadingContext.Consumer>
      {(context) => <ChildComponent {...props} loadingContext={context} />}
    </LoadingContext.Consumer>
  );
