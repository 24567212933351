/* eslint-disable no-undef */
import React, { Component } from "react";
import {
  BrowserRouter,
  Link,
  NavLink,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Airplane from "mdi-material-ui/Airplane";
import Train from "mdi-material-ui/Train";
import Bus from "mdi-material-ui/Bus";
import MenuIcon from "mdi-material-ui/Menu";
import BusClock from "mdi-material-ui/BusClock";
import Button from "@material-ui/core/Button";
import DotsVertical from "mdi-material-ui/DotsVertical";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Bed from "mdi-material-ui/Bed";
import Taxi from "mdi-material-ui/Taxi";
import Headset from "mdi-material-ui/Headset";
import Close from "mdi-material-ui/Close";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import packageJson from "../../package.json";
import CacheBuster from "./common/CacheBuster";
import {
  RegisterSuccessPage,
  RegisterPage,
  Page404,
  TripsPage,
  FlightSearchResultsPage,
  TravelPage,
  FlightBookPage,
  TrainSearchResultsPage,
  TrainBookingPage,
  TrainTicketPage,
  BusSearchResultsPage,
  BusBookingPage,
  BusTicketPage,
  CabsSearchResultsPage,
  CabBookingPage,
  CabTicketPage,
  HotelSearchResultsPage,
  HotelViewPage,
  HotelBookingPage,
  HotelTicketPage,
  ProfilePage,
  FlightTicketPage,
  ValidateUserPage,
  LandingPage,
  SSOPage,
  TripGainSSOPage,
} from "./Routes";
import { withLoadingContext } from "./common/LoadingProvider";
import {
  getProfile,
  getUserAuth,
  getServiceConfig,
  getSupportMatrix,
} from "./common/Utils";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import {
  TG_LS_USER_AUTH,
  TG_LS_PROFILE,
  TG_LS_SERVICE_CONFIG,
  TG_LS_SSO_LOGIN,
  B2C_EMAIL,
  TG_PARTNER_CATEGORY,
  TG_LS_MASTER_LOGO,
  TG_LS_MASTER_LOGO_WIDTH,
  TG_LS_MASTER_NAME,
} from "./common/constants";
import { doInit } from "../services/auth/AuthService";
import FlightBookingPreviewPage from "../pages/flights/FlightBookingPreviewPage";
const THEME = process.env.REACT_APP_THEME;
const DEFAULTLOGO = process.env.REACT_APP_DEFAULT_LOGO;

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileMenuEL: null,
      isAuth: false,
      openDrawer: false,
      corporateRole: "",
      isOpenMatrix: false,
      supportMatrixs: [],
      isLoading: false,
    };
  }

  showMenu = (isShow) => {
    this.userProfile = getProfile();
    if (this.userProfile !== null && this.userProfile.email !== B2C_EMAIL) {
      this.userType = this.userProfile.usertype;
      this.corporateRole = this.userProfile.corporate_role;
      if (isShow) {
        this.setState({ isAuth: isShow, corporateRole: this.corporateRole });
      } else {
        let user = getUserAuth();
        if (user !== null) {
          this.setState({ isAuth: true, corporateRole: this.corporateRole });
        } else {
          this.setState({ isAuth: false });
        }
      }
    }
  };

  initalizeLS = async () => {
    this.setState({
      isLoading: true,
    });
    let userProfile = getProfile();
    if (userProfile === null) {
      const res = await doInit({
        username: "b2c@choozofy.com",
        password: "thanks123",
      });
      localStorage.setItem(
        TG_LS_PROFILE,
        JSON.stringify(res.data.LoginResponse.profilemaster)
      );
      localStorage.setItem(
        TG_LS_USER_AUTH,
        JSON.stringify(res.data.LoginResponse.userauth)
      );
      localStorage.setItem(
        TG_LS_SERVICE_CONFIG,
        JSON.stringify(res.data.LoginResponse.serviceconfig)
      );
    }
    this.setState({
      isLoading: false,
    });
  };

  async componentDidMount() {
    await this.initalizeLS();
    this.showMenu();
  }

  handleCloseApp = () => {
    try {
      if (lcodeapp) {
        lcodeapp.closeWebView();
      }
    } catch (error) {}
  };

  handleClose = () => {
    this.setState({ profileMenuEL: null });
  };

  toggleDrawer = () => {
    this.setState({ openDrawer: !this.state.openDrawer });
  };

  handleMenu = (event) => {
    this.setState({ profileMenuEL: event.currentTarget });
  };

  logout = async () => {
    localStorage.removeItem(TG_LS_USER_AUTH);
    localStorage.removeItem(TG_LS_PROFILE);
    localStorage.removeItem(TG_LS_SERVICE_CONFIG);
    localStorage.removeItem(TG_LS_SSO_LOGIN);

    await this.initalizeLS();
    window.location.href = "/";
  };

  redirectToSSO = () => {
    return <SSOPage showMenu={this.showMenu} />;
  };

  redirectToTripGainSSO = () => {
    return <TripGainSSOPage showMenu={this.showMenu} />;
  };

  getSupportMatrix = async () => {
    this.props.loadingContext.show();
    const supportMatrixs = await getSupportMatrix(this.userProfile.corporateid);
    this.setState({
      supportMatrixs,
      isOpenMatrix: true,
    });
    this.props.loadingContext.hide();
  };

  handleSupportMatrixClose = () => {
    window.fcWidget.hide();
    this.setState({
      isOpenMatrix: false,
    });
  };

  showFCWidget = () => {
    if (window.fcWidget) {
      window.fcWidget.init({
        token: "f32db94a-9480-4f41-9928-173a1e832133",
        host: "https://wchat.freshchat.com",
        siteId: "choozofy",
      });
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  render() {
    const { profileMenuEL } = this.state;
    const profile = getProfile();
    const masterLogo = localStorage.getItem(TG_LS_MASTER_LOGO);
    const masterLogoWidth = localStorage.getItem(TG_LS_MASTER_LOGO_WIDTH);
    const masterName = localStorage.getItem(TG_LS_MASTER_NAME);
    let logoWidth = 100;
    if (masterLogoWidth !== null && masterLogoWidth !== undefined) {
      logoWidth = Number(masterLogoWidth);
    }
    let masterLogoUrl = undefined;
    if (masterLogo !== null && masterLogo !== undefined && masterLogo !== "" && (masterLogo.startsWith("http") || masterLogo.startsWith("https"))) {
      masterLogoUrl = masterLogo;
    }
    const serviceConfig = getServiceConfig();
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <div className={THEME}>
              <BrowserRouter>
                {!this.state.isLoading && (
                  <div className="main-app">
                    <AppBar position="sticky" id="main_toolbar">
                      <Toolbar disableGutters={true}>
                        <Grid container alignItems="center">
                          <Hidden lgUp>
                            <Grid item md={1} sm={1} xs={2}>
                              <IconButton
                                onClick={this.toggleDrawer}
                                color="inherit"
                                aria-label="Menu"
                              >
                                <MenuIcon />
                              </IconButton>
                            </Grid>
                          </Hidden>
                          <Grid item lg={2} md={2} sm={3} xs={4}>
                          {masterLogoUrl ? (
                            <Typography variant="h5">
                              <Link to={`/`}>
                                <img src={masterLogoUrl} alt={masterName} width={`${logoWidth}px`} className="logo" />
                              </Link>
                            </Typography>
                          ) : (
                            <Typography variant="h5">
                              <Link to={`/`}>
                                <img src={DEFAULTLOGO} alt="BEST TRAVEL DEALS" width={`${logoWidth}px`} className="logo" />
                              </Link>
                            </Typography>
                          )}
                          </Grid>
                          <Hidden mdDown>
                            <Grid item lg={6}>
                              {this.state.isAuth && (
                                <React.Fragment>
                                  <NavLink
                                    className="header-link ml--50"
                                    activeClassName="activePage"
                                    to="/travel"
                                  >
                                    <Button color="inherit">Travel</Button>
                                  </NavLink>
                                  <NavLink
                                    className="header-link"
                                    activeClassName="activePage"
                                    to="/trips"
                                  >
                                    <Button color="inherit">Trips</Button>
                                  </NavLink>
                                </React.Fragment>
                              )}
                            </Grid>
                          </Hidden>
                          <Grid
                            item
                            lg={4}
                            md={9}
                            sm={8}
                            xs={6}
                            className="profile-menu-container"
                          >
                            {getProfile() !== null && (
                              <div
                                className="sup-mat-btn"
                                title="Help"
                                onClick={this.getSupportMatrix}
                              >
                                <Headset /> <span>SUPPORT</span>
                              </div>
                            )}
                            {this.state.isAuth ? (
                              <React.Fragment>
                                <Button
                                  className="right"
                                  size="small"
                                  onClick={this.handleMenu}
                                  color="inherit"
                                  aria-haspopup="true"
                                >
                                  <Hidden xsDown>
                                    {profile === null
                                      ? ""
                                      : `${profile.title} ${profile.first_name} ${profile.last_name}`}
                                  </Hidden>
                                  <DotsVertical />
                                </Button>
                                <Menu
                                  anchorEl={profileMenuEL}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  open={Boolean(profileMenuEL)}
                                  onClose={this.handleClose}
                                >
                                  {this.userProfile.user_category !==
                                    TG_PARTNER_CATEGORY && (
                                      <>
                                    <MenuItem
                                      color="inherit"
                                      onClick={this.handleClose}
                                      component={Link}
                                      to="/profile#profile_1"
                                    >
                                      Profile
                                    </MenuItem>
                                    <MenuItem color="inherit" onClick={this.logout}>
                                    Logout
                                  </MenuItem>
                                  </>
                                  )}
                                  
                                  <small className="app-version">
                                    v {packageJson.version}
                                  </small>
                                </Menu>
                              </React.Fragment>
                            ) : (
                              <NavLink
                                className="header-link"
                                activeClassName="activePage"
                                to="/login"
                              >
                                <Button color="inherit">Login</Button>
                              </NavLink>
                            )}
                          </Grid>
                        </Grid>
                      </Toolbar>
                    </AppBar>
                    <Switch>
                      <Route exact path="/travel" component={TravelPage} />
                      <Route exact path="/travel#flight" component={TravelPage} />
                      <Route exact path="/travel#hotel" component={TravelPage} />
                      <Route exact path="/travel#bus" component={TravelPage} />
                      <Route exact path="/login" component={LandingPage} />
                      <Route
                        exact
                        path="/registration-success"
                        component={RegisterSuccessPage}
                      />
                      <Route
                        exact
                        path="/validate-user"
                        render={() => <ValidateUserPage />}
                      />
                      <Route exact path="/register" component={RegisterPage} />
                      <AuthenticatedRoute
                        exact
                        path="/travel"
                        component={TravelPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/trips"
                        component={TripsPage}
                      />

                      <AuthenticatedRoute
                        search="flt=:params"
                        path="/flights/search"
                        component={FlightSearchResultsPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/flights/book"
                        component={FlightBookPage}
                      />
                      <AuthenticatedRoute 
                        exact
                        path="/flights/book/preview"
                        component={FlightBookingPreviewPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/flights/eticket/:txid"
                        component={FlightTicketPage}
                      />

                      <AuthenticatedRoute
                        search="htls=:params"
                        path="/hotels/search"
                        component={HotelSearchResultsPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/hotels/details"
                        component={HotelViewPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/hotels/book"
                        component={HotelBookingPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/hotels/eticket/:txid"
                        component={HotelTicketPage}
                      />

                      <AuthenticatedRoute
                        search="q=:params"
                        path="/buses/search"
                        component={BusSearchResultsPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/buses/book"
                        component={BusBookingPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/buses/eticket/:txid"
                        component={BusTicketPage}
                      />

                      <AuthenticatedRoute
                        search="q=:params"
                        path="/trains/search"
                        component={TrainSearchResultsPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/trains/book"
                        component={TrainBookingPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/trains/eticket/:txid"
                        component={TrainTicketPage}
                      />

                      <AuthenticatedRoute
                        exact
                        path="/cabs/search"
                        component={CabsSearchResultsPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/cabs/book"
                        component={CabBookingPage}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/cabs/eticket/:txid"
                        component={CabTicketPage}
                      />

                      <AuthenticatedRoute
                        exact
                        path="/profile"
                        component={ProfilePage}
                      />

                      <Route
                        exact
                        path="/sso-quess/:encryptedKey"
                        render={() => this.redirectToSSO()}
                      />
                      <Route
                        exact
                        path="/sso-tripgain/:encryptedKey"
                        render={() => this.redirectToTripGainSSO()}
                      />

                      <Route exact path="/">
                        <Redirect to="/travel#flight" />
                      </Route>
                      <Route component={Page404} />
                    </Switch>
                    <SwipeableDrawer
                      className="mobile-menu-sidebar"
                      onOpen={this.toggleDrawer}
                      disableBackdropTransition
                      anchor="left"
                      open={this.state.openDrawer}
                      onClose={this.toggleDrawer}
                    >
                      <div
                        tabIndex={0}
                        onClick={this.toggleDrawer}
                        className="sidebar-content"
                      >
                        <div className="bb-1">
                          {masterLogoUrl ? (
                            <img src={masterLogoUrl} alt={masterName} className="logo" />
                          ) : (
                            <img src="/images/logo-transparent.png" alt="CHOOZOFY" className="logo" />
                          )}
                        </div>
                        <MenuList>
                          {serviceConfig && serviceConfig.flights && (
                            <MenuItem
                              className="mobile-menu-item"
                              component={Link}
                              to="/travel#flight"
                            >
                              {" "}
                              <Airplane className="rotate-90" />
                              &nbsp;&nbsp;Flights
                            </MenuItem>
                          )}
                          {serviceConfig && serviceConfig.hotels && (
                            <MenuItem
                              className="mobile-menu-item"
                              component={Link}
                              to="/travel#hotel"
                            >
                              {" "}
                              <Bed />
                              &nbsp;&nbsp;Hotels
                            </MenuItem>
                          )}
                          {serviceConfig && serviceConfig.buses && (
                            <MenuItem
                              className="mobile-menu-item"
                              component={Link}
                              to="/travel#bus"
                            >
                              <Bus />
                              &nbsp;&nbsp;Buses
                            </MenuItem>
                          )}
                          {serviceConfig && serviceConfig.trains && (
                            <MenuItem
                              className="mobile-menu-item"
                              component={Link}
                              to="/travel#train"
                            >
                              <Train />
                              &nbsp;&nbsp;Trains
                            </MenuItem>
                          )}
                          {serviceConfig && serviceConfig.cabs && (
                            <MenuItem
                              className="mobile-menu-item"
                              component={Link}
                              to="/travel#cab"
                            >
                              {" "}
                              <Taxi />
                              &nbsp;&nbsp;Cabs
                            </MenuItem>
                          )}
                          <MenuItem
                            className="mobile-menu-item"
                            component={Link}
                            to="/trips"
                          >
                            <BusClock />
                            Trips
                          </MenuItem>
                          {this.userProfile &&
                            this.userProfile.user_category ===
                              TG_PARTNER_CATEGORY && (
                              <MenuItem
                                className="mobile-menu-item"
                                onClick={this.handleCloseApp}
                              >
                                <Close />
                                Close
                              </MenuItem>
                            )}
                        </MenuList>
                      </div>
                    </SwipeableDrawer>
                  </div>
                )}
                <Drawer
                  anchor="right"
                  className="support-matrix-dailog"
                  disableBackdropClick={true}
                  disableEscapeKeyDown={true}
                  open={this.state.isOpenMatrix}
                  onClose={this.handleSupportMatrixClose}
                  aria-labelledby="support-matrix-title"
                >
                  <Grid container spacing={2}>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <Typography variant="h6">Help</Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        className="sup-mat-close"
                        onClick={this.handleSupportMatrixClose}
                      >
                        <Close title="Close" />
                      </Button>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="subtitle1">
                        Having trouble understanding Choozofy Software
                      </Typography>
                      <Typography variant="body2">
                        We have everything to clear you up in our help center.
                      </Typography>
                      {this.state.supportMatrixs.map((m, i) => (
                        <div key={i} className="support-matrix-row">
                          <Typography variant="body1" className="bold">
                            {m.name}
                          </Typography>
                          <div className="support">
                            <div>
                              <Typography variant="caption">
                                Phone Number
                              </Typography>
                              <Typography variant="body2" className="bold">
                                {m.contact_numbers.split(",").map((p, index) => (
                                  <p key={index}>{p}</p>
                                ))}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="caption">Email</Typography>
                              <Typography variant="body2" className="bold">
                                {m.email.split(",").map((e, index) => (
                                  <p key={index}>{e}</p>
                                ))}
                              </Typography>
                            </div>
                          </div>
                          <Typography>{m.instructions}</Typography>
                        </div>
                      ))}
                      <div className="text-center">
                        <Button
                          variant="outlined"
                          color="secondary"
                          className="mb-16 bold"
                          onClick={this.showFCWidget}
                        >
                          Click here for Live Chat
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Drawer>
              </BrowserRouter>
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

export default withLoadingContext(Main);
