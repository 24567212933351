import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FlightCardMini from "./FlightCardMini";
import Typography from "@material-ui/core/Typography";
import AirplaneTakeoff from "mdi-material-ui/AirplaneTakeoff";
import {getConnectingTime, formatToMonthDate, getType} from '../../common/Utils';

class ReviewFlight extends React.PureComponent {
  domint = this.props.domint;
  journeyType = this.props.journeyType;
  amenities = [];
  amenities1 = [];
  amenities2 = [];
  fareType = "";
  render() {
    let allFlights = [],
      fls = {},
      flightsLegsList = [];
    if (!Array.isArray(this.props.selectedFlights[0].FlightRecommendation)) {
      allFlights.push(this.props.selectedFlights[0].FlightRecommendation);
    } else {
      allFlights = this.props.selectedFlights[0].FlightRecommendation;
    }

    if (this.domint === "international" && this.journeyType === "RoundTrip") {
      fls = allFlights[0].flights[0].FlightObject;
      flightsLegsList = fls[0].flightlegs[0].FlightLeg;
      this.amenities1 = fls[0].flightfares[0].FlightFare.amenities.split(",");
      this.amenities2 = fls[1].flightfares[0].FlightFare.amenities.split(",");
    }

    return (
      <div>
        {this.domint === "international" && this.journeyType === "RoundTrip" ? (
          <div>
            {Array.isArray(fls) ? (
              <div>
                <Card>
                  <CardContent>
                    <Typography className="flight-title" variant="h6">
                      <AirplaneTakeoff /> &nbsp;&nbsp;Departing flight : {formatToMonthDate(fls[0].depdate)}
                    </Typography>
                    {Array.isArray(fls[0].flightlegs[0].FlightLeg) ? (
                      fls[0].flightlegs[0].FlightLeg.map((flight, index) => {
                        return (
                          <div key={index}>
                            <FlightCardMini flightKey={fls[0].flightkey} flight={flight} />
                            {fls[0].flightlegs[0].FlightLeg.length > index + 1 && (
                              <div className="text-center change-over-msg">
                                <Typography variant="body1">
                                  Change planes at&nbsp;<strong>
                                    {flight.destination_name} ({flight.destination})
                                  </strong>, Connecting Time:&nbsp;
                                  <strong>
                                    {getConnectingTime(flight.arrtime, flight.arrdate, flightsLegsList[index + 1].deptime, flightsLegsList[index + 1].depdate)}&nbsp;
                                  </strong>
                                  | Connecting flight may depart from a different terminal
                                </Typography>
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <FlightCardMini flightKey={fls[0].flightkey} flight={fls[0].flightlegs[0].FlightLeg} />
                    )}
                    <div>
                      <Typography className="right">
                        Amenities:
                        {this.amenities1.map((x, idx) => {
                          return ['pmeal', 'pseat'].indexOf(x) === -1 && (
                            <span className="caption bold" key={idx}>
                              {getType(x)} &nbsp; {this.amenities1.length - 1 === idx ? "" : "|"} &nbsp;
                            </span>
                          )}
                        )}
                      </Typography>
                      <br />
                    </div>
                  </CardContent>
                </Card>
                <br />
                <Card>
                  <CardContent>
                    <Typography className="flight-title" variant="h6">
                      <AirplaneTakeoff /> &nbsp;&nbsp;Returning flight : {formatToMonthDate(fls[1].depdate)}
                    </Typography>
                    {Array.isArray(fls[1].flightlegs[0].FlightLeg) ? (
                      fls[1].flightlegs[0].FlightLeg.map((flight, index) => {
                        return (
                          <div key={index}>
                            <FlightCardMini flightKey={fls[1].flightkey} flight={flight} />
                            {fls[1].flightlegs[0].FlightLeg.length > index + 1 && (
                              <div className="text-center change-over-msg">
                                <Typography variant="body1">
                                  Change planes at&nbsp;
                                  <strong>
                                    {flight.destination_name} ({flight.destination})
                                  </strong>, Connecting Time:
                                  <strong>
                                    {getConnectingTime(
                                      flight.arrtime,
                                      flight.arrdate,
                                      fls[1].flightlegs[0].FlightLeg[index + 1].deptime,
                                      fls[1].flightlegs[0].FlightLeg[index + 1].depdate
                                    )}
                                  </strong>
                                  | Connecting flight may depart from a different terminal
                                </Typography>
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <FlightCardMini flightKey={fls[1].flightkey} flight={fls[1].flightlegs[0].FlightLeg} />
                    )}

                    <div>
                      <Typography className="right">
                        Amenities:{" "}
                        {this.amenities2.map((x, idx) => {
                          return ['pmeal', 'pseat'].indexOf(x) === -1 && (
                            <span className="caption bold" key={idx}>
                              {getType(x)} &nbsp; {this.amenities2.length - 1 === idx ? "" : "|"} &nbsp;
                            </span>
                          )}
                        )}
                      </Typography>
                      <br />
                    </div>
                  </CardContent>
                </Card>
              </div>
            ) : (
              <Card>
                <CardContent>
                  {fls.flightlegs[0].FlightLeg.map((flight, index) => {
                    return <FlightCardMini flightKey={fls[0].flightkey} key={index} flight={flight} />;
                  })}
                </CardContent>
              </Card>
            )}
            <br />
          </div>
        ) : (
          allFlights.map((fr, index) => {
            return fr.flights.map((fl, indx) => {
              let fo = fl.FlightObject;
              this.fareType = fo.flightfares[0].FlightFare.faretype;
              this.amenities = fo.flightfares[0].FlightFare.amenities.split(",");
              let flights,
                isArray = false;
              if (Array.isArray(fo.flightlegs[0].FlightLeg)) {
                flights = fo.flightlegs[0].FlightLeg;
                isArray = true;
              } else {
                flights = fo.flightlegs[0].FlightLeg;
              }
              return (
                <div key={indx}>
                  <Card>
                    <CardContent className="departing-arrival-flight">
                      {index === 0 ? (
                        <Typography className="flight-title" variant="h6">
                          <AirplaneTakeoff /> &nbsp;&nbsp;Departing flight : {formatToMonthDate(fo.depdate)}
                        </Typography>
                      ) : (
                        <Typography className="flight-title" variant="h6">
                          <AirplaneTakeoff /> &nbsp;&nbsp;Returning flight : {formatToMonthDate(fo.depdate)}
                        </Typography>
                      )}
                      {isArray ? (
                        <div>
                          {flights.map((flight, index) => {
                            let dest = flights[flights.length - 1].destination;
                            return (
                              <div key={index}>
                                <FlightCardMini flightKey={fo.flightkey} flight={flight} />
                                {dest !== flight.destination && (
                                  <div className="text-center change-over-msg">
                                    <Typography variant="body1">
                                      Change planes at&nbsp;
                                      <strong>
                                        {flight.destination_name} ({flight.destination}),&nbsp;
                                      </strong>
                                      Connecting Time:
                                      <strong>
                                        {getConnectingTime(flight.arrtime, flight.arrdate, flights[index + 1].deptime, flights[index + 1].depdate)}
                                      </strong>| Connecting flight may depart from a different terminal
                                    </Typography>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <FlightCardMini flight={flights} />
                      )}
                      <div style={{display: 'flex', justifyContent:'space-between'}}>
                        <div>
                          <Typography variant="caption">Fare Type</Typography>
                          <Typography className="capitalize fare-type_custom">{this.fareType}</Typography>
                        </div>
                        <div>
                        <Typography variant="caption">Amenities</Typography>
                          <Typography>
                          {this.amenities.map((x, idx) => {
                            return ['pmeal', 'pseat'].indexOf(x) === -1 && (
                              <span className="caption bold" key={idx}>
                                {getType(x)} &nbsp; {this.amenities.length - 1 === idx ? "" : "|"} &nbsp;
                              </span>
                            )}
                          )}
                        </Typography>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <br />
                </div>
              );
            });
          })
        )}
      </div>
    );
  }
}

export default ReviewFlight;
