import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import ContentLoader from "react-content-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

export const CompLoader = (props) => {
  if (props.error) {
    return (
      <Dialog
        disableBackdropClick={true}
        open={true}
        aria-labelledby="page-loading"
      >
        <DialogContent>
          Error! <button onClick={props.retry}>Retry</button>
        </DialogContent>
      </Dialog>
    );
  } else if (props.pastDelay) {
    return (
      <Dialog
        disableBackdropClick={true}
        open={true}
        aria-labelledby="page-loading"
      >
        <DialogContent>
          <CircularProgress color="secondary" thickness={5} />
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
};

export const Loading = ({ message }) => {
  return (
    <Dialog disableBackdropClick={true} open={true} aria-labelledby="loading">
      <DialogContent className="text-center">
        {message !== "" && message !== undefined && (
          <Typography>{message}</Typography>
        )}
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export const FiltersLoader = () => {
  return (
    <ContentLoader
      style={{ marginLeft: -20 }}
      height={475}
      width={300}
      speed={2}
      primarycolor="#f2f2f2"
      secondarycolor="#fbbeab"
    >
      <rect x="29" y="43.74" rx="0" ry="0" width="248" height="5.8" />
      <rect x="24" y="35.27" rx="0" ry="0" width="24" height="21" />
      <rect x="259" y="35.27" rx="0" ry="0" width="24" height="22" />
      <rect x="33.8" y="106.25" rx="0" ry="0" width="63" height="15" />
      <rect x="33.8" y="138.25" rx="0" ry="0" width="74" height="37" />
      <rect x="113.8" y="138.25" rx="0" ry="0" width="74" height="37" />
      <rect x="193.8" y="138.25" rx="0" ry="0" width="74" height="37" />
      <rect x="33.8" y="209.25" rx="0" ry="0" width="69" height="15" />
      <rect x="33.8" y="234.25" rx="0" ry="0" width="55" height="66" />
      <rect x="93.8" y="234.25" rx="0" ry="0" width="55" height="66" />
      <rect x="154.8" y="234.25" rx="0" ry="0" width="55" height="66" />
      <rect x="214.8" y="234.25" rx="0" ry="0" width="55" height="66" />
      <rect x="36.8" y="328.25" rx="0" ry="0" width="72" height="15" />

      <rect x="33.8" y="362.25" rx="0" ry="0" width="15" height="13" />
      <rect x="50.8" y="364.25" rx="0" ry="0" width="179" height="7" />
      <rect x="33.8" y="382.25" rx="0" ry="0" width="15" height="13" />
      <rect x="50.8" y="384.25" rx="0" ry="0" width="179" height="7" />
    </ContentLoader>
  );
};

export const FlightsLoader = () => {
  return (
    <ContentLoader
      height={70}
      width={900}
      speed={2}
      primarycolor="#f2f2f2"
      secondarycolor="#fbbeab"
    >
      <circle cx="36.73" cy="37.73" r="15" />
      <rect x="25" y="60" rx="4" ry="4" width="60" height="6.4" />

      <rect x="150" y="24" rx="4" ry="4" width="105" height="6.4" />
      <rect x="150" y="38.27" rx="3" ry="3" width="85" height="5.3" />

      <rect x="290" y="24" rx="4" ry="4" width="105" height="6.4" />
      <rect x="290" y="38.27" rx="3" ry="3" width="85" height="5.3" />

      <rect x="430" y="24" rx="4" ry="4" width="105" height="6.4" />
      <rect x="430" y="38.27" rx="3" ry="3" width="85" height="5.3" />

      <rect x="570" y="24" rx="4" ry="4" width="105" height="6.4" />
      <rect x="570" y="38.27" rx="3" ry="3" width="85" height="5.3" />

      <rect x="710" y="17.27" rx="0" ry="0" width="130" height="39" />
    </ContentLoader>
  );
};

export const BottomBarLoader = () => {
  return (
    <ContentLoader
      height={92}
      width={1200}
      speed={2}
      primarycolor="#f2f2f2"
      secondarycolor="#fbbeab"
    >
      <rect x="57" y="28.71" rx="4" ry="4" width="117" height="6.336" />
      <rect x="56" y="42.84" rx="3" ry="3" width="85" height="5.2569" />
      <rect x="1052.01" y="22.03" rx="0" ry="0" width="112" height="38.61" />
      <rect x="214" y="27.05" rx="0" ry="0" width="128" height="6.93" />
      <rect x="221" y="41.9" rx="0" ry="0" width="118" height="6.93" />
      <rect x="451" y="25.07" rx="0" ry="0" width="127" height="6.93" />
      <rect x="452" y="41.9" rx="0" ry="0" width="110" height="4.9896" />
      <rect x="652" y="24.06" rx="0" ry="0" width="127" height="6.93" />
      <rect x="654" y="39.91" rx="0" ry="0" width="103" height="7.92" />
      <rect x="919.01" y="28.8" rx="0" ry="0" width="100" height="22" />
      <rect x="383" y="5.8" rx="0" ry="0" width="8" height="76" />
      <rect x="844.01" y="8.8" rx="0" ry="0" width="7" height="76" />
    </ContentLoader>
  );
};

export const PriceLoader = () => {
  return (
    <ContentLoader
      style={{ marginLeft: -20 }}
      height={92}
      width={300}
      speed={2}
      primarycolor="#f2f2f2"
      secondarycolor="#fbbeab"
    >
      <rect
        x="29"
        y="43.74"
        rx="0"
        ry="0"
        width="248.19480000000001"
        height="5.788800000000001"
      />
      <rect x="24" y="35.27" rx="0" ry="0" width="24" height="21" />
      <rect x="259" y="35.27" rx="0" ry="0" width="24" height="22" />
    </ContentLoader>
  );
};

export const HotelsResultsLoader = () => {
  return (
    <ContentLoader
      height={140}
      width={884}
      speed={2}
      primarycolor="#f2f2f2"
      secondarycolor="#fbbeab"
    >
      <rect x="3" y="4.8" rx="0" ry="0" width="145.65" height="145.19" />
      <rect x="255" y="19" rx="3" ry="3" width="250.6" height="12.8" />
      <rect x="255" y="50.62" rx="3" ry="3" width="231.4" height="12.8" />
      <rect x="255" y="80.62" rx="3" ry="3" width="100.4" height="12.8" />
      <rect x="255" y="110.62" rx="3" ry="3" width="106.4" height="12.8" />
      <rect x="557.48" y="110.62" rx="3" ry="3" width="106.8" height="12.8" />
      <rect x="726.01" y="140.86" rx="3" ry="3" width="127.8" height="30.6" />
    </ContentLoader>
  );
};

export const WhyChooseUsLoader = () => {
  return (
    <ContentLoader
      height={240}
      width={320}
      speed={2}
      primarycolor="#f2f2f2"
      secondarycolor="#fbbeab"
    >
      <rect x="0" y="0" rx="0" ry="0" width="320" height="180" />
      <rect x="15" y="190" rx="2" ry="2" width="280" height="8" />
      <rect x="15" y="201" rx="2" ry="2" width="220" height="6" />
      <rect x="15" y="220" rx="2" ry="2" width="280" height="6" />
      <rect x="15" y="230" rx="2" ry="2" width="220" height="6" />
    </ContentLoader>
  );
};

export default {
  Loading: Loading,
  FiltersLoader: FiltersLoader,
  FlightsLoader: FlightsLoader,
  HotelsResultsLoader: HotelsResultsLoader,
  WhyChooseUsLoader: WhyChooseUsLoader,
};
