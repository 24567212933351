import React from "react";
import { Route, Redirect } from "react-router-dom";
import {getUserAuth} from './common/Utils';
import {TG_LS_SERVICE_CONFIG} from './common/constants';

function isAuthenticated() {
  let pathName = window.location.pathname;
  if (pathName !== "") {
    var userAuth = getUserAuth();
    if (userAuth) {
      if (userAuth.authtoken) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
}

function canAccessRoute() {
  let serviceConfig = {};
  const config = localStorage.getItem(TG_LS_SERVICE_CONFIG);
  if (config !== null && config !== undefined && config !== "" && config !== "undefined") {
    serviceConfig = JSON.parse(config);
    const pathName = window.location.pathname;
    if (
      (serviceConfig.flights && pathName === "/travel#flight") ||
      (serviceConfig.hotels && pathName === "/travel#hotel") ||
      (serviceConfig.cabs && pathName === "/travel#cab") ||
      (serviceConfig.trains && pathName === "/travel#train") ||
      (serviceConfig.buses && pathName === "/travel#bus") ||
      ["/trips", "/profile"].indexOf(pathName) > -1
    ) {
      return true;
    } else if (["/travel#flight", "/travel#hotel", "/travel#cab", "/travel#train", "/travel#bus"].indexOf(pathName) > -1) {
      return false;
    }
    return true;
  } else {
    return true;
  }
}

export const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        canAccessRoute() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/travel" />
        )
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
            search: `?redirect_to=${props.location.pathname}${props.location.search}${props.location.hash}`,
          }}
        />
      )
    }
  />
);
